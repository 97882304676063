import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';
import type z from 'zod';
import { type FetchConfig, fetchApi } from '~/api/fetch';
import { InvalidResponseSchemaError } from '~/errors/invalid-response-schema';

export type FetchMethod<RequestBody, ResponseType> = (
  url: string,
  schema: z.ZodType<ResponseType>,
  config?: FetchConfig<RequestBody>,
) => Promise<ResponseType>;

export function useFetch() {
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = useCallback(
    async <RequestBody, ResponseType>(
      url: string,
      schema: z.ZodType<ResponseType>,
      config?: FetchConfig<RequestBody>,
    ) => {
      const token = await getAccessTokenSilently();

      const response = await fetchApi<RequestBody, ResponseType>(url, {
        ...config,
        options: {
          ...config?.options,
          headers: {
            Authorization: `Bearer ${token}`,
            ...config?.options?.headers,
          },
        },
      });

      const validatedResponse = schema.safeParse(response);

      if (!validatedResponse.success) {
        const { error } = validatedResponse;
        const exception = new InvalidResponseSchemaError(error);

        Sentry.captureException(exception);
        throw exception;
      }

      return validatedResponse.data;
    },
    [getAccessTokenSilently],
  );

  return { fetchData };
}
