import type { ZodError } from 'zod';

export class InvalidResponseSchemaError extends Error {
  static readonly PREFIX = 'Invalid response schema:';

  constructor(details: ZodError) {
    super(`${InvalidResponseSchemaError.PREFIX} ${details}`);
    this.name = 'InvalidResponseSchema';
    Object.setPrototypeOf(this, InvalidResponseSchemaError.prototype);
  }
}
